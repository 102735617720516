@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  background-color: #0d0e0f;
  color: #ffffff;
  touch-action: pan-y;
  overflow-x: hidden;
  overflow-y: hidden;
}

#root {
  width: 100%;
  height: 100%;
}
